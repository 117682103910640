.header {
  display: flex;
  flex-direction: row;
  height: 80px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  align-items: center;
  padding: 16px;
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 80;
}

.headerNotFound {
  display: flex;
  flex-direction: row;
  height: 80px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
}

.headerBack {
  margin-right: 16px;
}

.headerImage {
  border-radius: 50%;
}

.headerContent {
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-height: 75px;
}

.headerContentStore {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  color: #000000;
}

.headerContentInvoice {
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  margin: 0;
  color: #42454D;
}

.footer {
  height: 76px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 16px;
  background-color: white;
}

.footerButton {
  cursor: pointer;
  background-color: #F5B22D;
  border: none;
  width: 100%;
  height: 100%;
  font-weight: bold;
  border-radius: 5px;
}

.footerButtonDisable {
  background-color: #FFE1A7;
  border: none;
  width: 100%;
  height: 100%;
  font-weight: bold;
  border-radius: 5px;
  color: #42454D;
  opacity: 0.6;
}

.footerButtonDisableSubmit {
  background-color: #232C44;
  border: none;
  width: 100%;
  height: 100%;
  font-weight: bold;
  border-radius: 5px;
  color: #42454D;
  opacity: 0.8;
}

.mainContainer {
  padding: 4rem 2rem;
}

.image {
  height: auto;
  width: 120px;
}

.title {
  margin: 0;
  margin-top: 70px;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
}

.desc {
  margin: 0;
  margin-top: 12px;
  font-weight: normal;
  font-size: 12px;
  --tw-text-opacity: 1;
  color: rgb(66 69 77/var(--tw-text-opacity));
}

.formContainer {
  margin-top: 3rem;
  max-width: 100%;
}

.formTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #14151A;
}

.mainContentPaymentDropdown {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #CED2D9;
  align-items: center;
  padding-bottom: 10px 
}

.mainContentVoucherInput {
  margin-left: 5px;
  border: none;
  width: 100%;
  height: 25px;
  font-size: 14px;
}

.countryCode {
  background: #F1F2F5;
  border: 1px solid #CED2D9;
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #42454D;
  padding: 0px 3px;
}

.keytaButton {
  cursor: 'pointer';
  margin-top: 6rem;
  min-width: 150px;
  border-radius: 8px;
  --tw-bg-opacity: 1;
  background-color: rgb(245 178 45/var(--tw-bg-opacity));
  padding: 0.625rem 1.25rem;
  text-align: center;
  --tw-text-opacity: 1;
  font-size: 14px;
  line-height: 20px;
  border: none
}

.otpForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 300px;
  gap: 0.25rem;
  margin-top: 1.5rem;
  margin-bottom: 20px;
}

.otpInput {
  max-width: 30px;
  text-align: center;
  border: none;
  border-bottom: 2px solid #ced2d9;
}

.setIsVerify {
  margin: 0;
  margin-top: 6rem;
  background-color: aquamarine;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
}
.main {
  padding-top: 82px;
  padding-bottom: 82px
}

.mainContent {
  padding-top: 16px;
  border-bottom: 1px solid #E8EAEF;
}

.content {
  opacity: 0;
  /* background-color: cornflowerblue; */
  transition: 1s;
}

.fadeIn {
  opacity: 1;
}

.accordion {
  padding: 0px 20px
}

.lineBreak {
  padding-top: 5px;
  border-bottom: 1px solid #E8EAEF;
}

.mainContentTitle {
  margin: 0;
  font-size: 18px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 5px;
  font-weight: bold;
}

.mainContentProduct {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mainContentProductNameTruncate {
  margin: 0;
  padding-left: 16px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-top: 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.mainContentProductName {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 0px;
  padding-top: 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.mainContentTotal {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

.mainContentTotalTitle {
  margin: 0;
  font-size: 18px;
  list-style: 24px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: bold;
  color: #14151A;
}

.mainContentTotalPrice {
  margin: 0;
  font-size: 14px;
  list-style: 24px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: bold;
  color: #14151A;
}

.mainContentVoucher {
  padding: 18px 16px; 
  border-bottom: 1px solid #E8EAEF;
}

.mainContentVoucherBox {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #CED2D9;
}

.mainContentVoucherInput {
  border: none;
  width: 100%;
  height: 25px;
  font-size: 16px;
}

.mainContentPayment {
  padding: 10px 16px;
  align-items: center;
}

.mainContentPhoneNumber {
  margin: 0;
  margin-top: 5px;
  margin-bottom: 8px;
  margin-right: 8px;
  background-color: #F1F2F5;
  border: 1px solid #CED2D9;
  border-radius: 4px;
  width: 36px;
  padding: 1px;
  font-size: 14px;
}

.mainContentPaymentDropdown {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #CED2D9;
  align-items: center;
}

.mainContentPaymentDropdown__v2 {
  display: flex;
  flex-direction: row;
  border: 1px solid #CED2D9;
  padding: 8px;
  border-radius: 12px;
  align-items: center;
}

.mainContentPaymentDropdownInput {
  font-size: 12px;
  color: #CED2D9;
  margin-top: 0px;
}

.mainPaymentSubmitPage {
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 16px;
}

.mainContentLabel {
  position: absolute;
  background: #F1F2F5;
  border-radius: 7px;
  font-size: 14px;
  margin: 16px;
  padding: 16px;
}

.mainContentKeytaLabel {
  display: flex;
  flex-direction: row;
  background: #FFF7E8;
  border-radius: 7px;
  font-size: 14px;
  margin: 16px;
  padding: 16px 16px 16px 8px;
}

.labelImage {
  margin-top: 4px;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  /* margin-top: 5px */
}

.mainContentBottom {
  position: absolute;
  bottom: 80px;
  left: 0;
}

.paymentSubmitPage {
  text-align: center;
}

.paymentSubmitPageImage {
  padding: 16px;
}

.paymentSubmitPageCounter {
  text-align: center;
  background-color: #FFF7E8;
  border-radius: 8px;
  height: 68px;
  padding: 10px 0px;
  width: 100%;
  margin-bottom: 20px;
}

.paymentSubmitPageCounterTitle {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  margin-bottom: 5px;
}

.paymentSubmitPageCounterTimer {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.waitingPage {
  background-color: #F9FAFB;
  padding: 95px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.waitingPageImage {
  margin-top: 15px;
  margin-bottom: 12px;
  width: 100px;
  height: 100px
}

.waitingPageInfo {
  text-align: center;
  margin-bottom: 15px;
  width: 100%
}

.waitingPageInfoTitle {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 0;
  margin-bottom: 8px;
}

.waitingPageInfoDesc {
  font-size: 14px;
  font-weight: normal;
  color: #42454D;
  margin: 0
}

.waitingPageDetail {
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  background-color: white;
}

.waitingPageDetailTitle {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
}

.waitingPageDetailContent {
  margin: 10px 0px;
  font-weight: normal;
  font-size: 12px;
}

.waitingPageDetailInfo {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mainPaymentSubmitMethod {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #F1F2F5;
  border-bottom: 1px solid #F1F2F5;
  padding: 18px 0px;

}

.mainPaymentSubmitMethodImage {
  height: 15px;
  margin-right: 16px;
}

.mainPaymentSubmitMethodText {
  font-size: 14px;
}

.mainPaymentSubmitMethodRetailText {
  font-size: 14px;
  margin-left: auto;
  margin-right: 0;
}

.mainPaymentSubmitAccountBro {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 18px 0px;
  padding-left: 32px;
}

.mainPaymentSubmitAccount {
  display: flex;
  width: 100%;
  flex-direction: row;
  border-bottom: 1px solid #F1F2F5;
}

.mainPaymentSubmitAccountDetail {
  margin-bottom: 16px;
}

.mainPaymentSubmitAccountInfo {
  margin-top: 12px;
  font-size: 14px;
  color: #F5B22D
}

.mainText {
  font-size: 14px;
  color: #42454D;
}

.mainTitle {
  font-size: 16px;
  font-weight: bold;
  color: #14151A;
}

.btnCopy {
  color: #023E8A;
  font-weight: bold;
  background-color: white;
  border: none;
}

.notFoundPage {
  background-color:#F9FAFB   ;
  padding: 82px 16px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notFoundPageInfo {
  text-align: center;
  margin-bottom: 40px;
  height: 100%
}

.notFoundPageInfoTitle {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 0;
  margin-bottom: 8px;
}

.notFoundPageInfoDesc {
  font-size: 14px;
  font-weight: normal;
  color: #42454D;
  margin: 0;
}

.cancel {
  height: 76px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  padding: 16px;
  background-color: white;
}

.buttonCancel {
  cursor: pointer;
  background-color: white;
  border: none;
  width: 100%;
  height: 100%;
  font-weight: bold;
  border-radius: 5px;
  font-size: 16px;
  color: #023E8A
}

.footer {
  z-index: 60;
  height: 76px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 16px;
  background-color: white;
}

.footerButton {
  cursor: pointer;
  background-color: #F5B22D;
  border: none;
  width: 100%;
  height: 100%;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.buttonLogins {
  cursor: pointer;
  padding: 5px;
  background-color: #F5B22D;
  border: none;
  width: 100%;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.footerButtonDisable {
  background-color: #FFE1A7;
  border: none;
  width: 100%;
  height: 100%;
  font-weight: bold;
  border-radius: 5px;
  color: #42454D;
  opacity: 0.6;
}

.footerMultiple {
  z-index: 65;
  height: 76px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 16px;
  background-color: white;
  display: flex;
  flex-direction: row;
}

.footerButton {
  cursor: pointer;
  background-color: #F5B22D;
  border: none;
  width: 100%;
  height: 100%;
  font-weight: bold;
  border-radius: 5px;
}

.footerButtonMultiple {
  background-color: #F5B22D;
  border: none;
  width: 100%;
  height: 100%;
  font-weight: bold;
  border-radius: 5px;
}

.footerButtonMultiple:nth-child(1) {
  background-color: #E8EAEF;
  border: none;
  width: 100%;
  height: 100%;
  font-weight: bold;
  border-radius: 5px;
  margin-right: 16px;
}

.voucher {
  padding: 15px 15px;
  padding-bottom: 0px;
}

.voucher__content {
  background: #FFF7E8;
  border-radius: 8px;
  height: 52px;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  position: relative;
}

.voucher__content__selected {
  background: #FFF7E8;
  border-radius: 8px;
  height: 52px;
  padding: 0px 16px;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  position: relative;
}

.voucher__content__selected__title {
  color: #42454D;
  font-size: 16px;
  font-weight: bold;

}

.voucher__image__banner {
  height: 25px;
  width: 130px
}

.voucher__image__text {
  position: absolute;
  font-size: 10px;
  font-weight: bold;
  color: white;
  bottom: 40%;
  left: 2%;
}

.voucher__content__text {
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #42454D;
}

.voucher__image__button {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.voucher__container {
  padding: 80px 0px;
  height: 100vh;
  transition: 1s;
  z-index: 80;
  background: #F9F9F9;
}

.voucher__claim {
  padding: 20px 1.1rem;
  background: #FFFFFF;
}

.voucher__claimed {
  padding: 20px 1.1rem;
  background: #FFFFFF;
  height: 80vh
}

.voucher__title {
  margin: 0px 0px;
  margin-bottom: 20px;
  color: #14151A;
  font-size: 18px;
}

.voucher__input {
  margin-top: 10px;
}

.voucher__button {
  cursor: pointer;
  width: 70px;
  height: 24px;
  background: #F5B22D;
  border: none;
  border-radius: 5px;
  color: #14151A;
  font-weight: bold;
}

.voucher__button__disable {
  background-color: #FFE1A7;
  color: #42454D;
  opacity: 0.6;
}

.voucher__no__contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px
}

.voucher__contents {
  margin-bottom: 20px;
}

.voucher__content__data {
  width: 100%;
  height: 120px;
  border-radius: 8px;
  background: #FDFBF4;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
}

.voucher__content__top {
  display: grid;
  width: 100%;
  padding: 10px 10px;
  grid-template-columns: 1fr 3fr;
  height: 70%;
}

.voucher__content_top__image {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  background: black;
}

.voucher__content__top__detail__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.voucher__content__top__detail__title__text {
  margin: 0;
  margin-left: 10px;
  font-size: 14px;
}

.voucher__content__top__detail__description {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.voucher__content__top__detail__description__text {
  margin-bottom: 0;
}

.voucher__content__bot {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
}

.voucher__content__bot__text {
  color: #81868F;
  font-size: 10px;
}

.voucher__content__bot__detail {
  font-size: 14px;
  font-weight: bold;
  color: #232C44;
}

.voucher__no__content {
  font-size: 14px;
  color: #CED2D9;
}

.customer__detail {
  margin-bottom: 10px;
  padding: 10px 0px;
  border-bottom: 1px solid #E8EAEF;
}

.customer__detail__content {
  font-size: 14px;
  padding: 5px 16px;
  display: flex;
  flex-direction: row;
}

.line {
  border-top: 1px solid #E8EAEF;
}

.warning {
  padding: 16px 16px;
  padding-bottom: 1px;
  /* background: #E60036;
  border-radius: 8px */
}
.warning__content {
  background: #E60036;
  border-radius: 8px;
  height: 88px;
  display: flex;
  flex-direction: column;
}

.card__form__input {
  width: 100%;
  font-size: 16px;
  padding: 5px
}

.footerButtonDisableSubmit {
  background-color: #232C44;
  border: none;
  width: 100%;
  height: 100%;
  font-weight: bold;
  border-radius: 5px;
  color: #42454D;
  opacity: 0.8;
}

.buttonLogin {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 35px;
  padding-top: 10px;
}

.loginHeader {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.loginTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #14151A;
}

.loginDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #42454D;
}

.suggestContainer {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 5px 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  border-radius: 8px;
}

.suggestion:last-child {
  border-bottom: none;
}

.suggestion {
  padding: 16px 0px;
  min-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #42454D;
}